import { template as template_b17b9c6a9d724535adc63a3381a06775 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b17b9c6a9d724535adc63a3381a06775(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
