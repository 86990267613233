import { template as template_6f1d8077db2249a68b98be6429984206 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_6f1d8077db2249a68b98be6429984206(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_6f1d8077db2249a68b98be6429984206(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_6f1d8077db2249a68b98be6429984206(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
